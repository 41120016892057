exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-backtowork-index-jsx": () => import("./../../../src/pages/backtowork/index.jsx" /* webpackChunkName: "component---src-pages-backtowork-index-jsx" */),
  "component---src-pages-cv-index-jsx": () => import("./../../../src/pages/cv/index.jsx" /* webpackChunkName: "component---src-pages-cv-index-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-research-index-jsx": () => import("./../../../src/pages/research/index.jsx" /* webpackChunkName: "component---src-pages-research-index-jsx" */),
  "component---src-pages-xuexuemaimai-index-jsx": () => import("./../../../src/pages/xuexuemaimai/index.jsx" /* webpackChunkName: "component---src-pages-xuexuemaimai-index-jsx" */)
}

